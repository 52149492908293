import { Grid } from "@material-ui/core";
import logo from "../../assets/svgs/logo-omniq-01.svg";

import { makeStyles } from "@material-ui/core/styles";
;

const useStyles = makeStyles((theme) => ({
  h1: {
    fontSize: "46px",
    color: "#575757",
  },
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <div>
      <center>
        <Grid item xs={5}>
          <img mb={50} src={logo} alt="Logo" />
        </Grid>
        <h1 className={classes.h1}>404 - Not Found!</h1>
      </center>
    </div>
  );
};

export default NotFound;
