import ManagerLayout from "./layout";
import { Route, Redirect } from "react-router-dom";
import { SessionHelper } from "../../utils";
;

const ManagerRoute = ({ component: Component, roles, exact, ...rest }) => (
  <Route
    strict
    exact
    {...rest}
    render={(props) => {
      const currentUser = SessionHelper.currentUser;
      if (!currentUser) {
        return <Redirect to={{ pathname: "/login" }} />;
      }

      if (roles && roles.some((r) => currentUser.roles.indexOf(r) === -1)) {
        return <Redirect to={{ pathname: "/login" }} />;
      }

      return (
        <ManagerLayout>
          <Component {...props} />
        </ManagerLayout>
      );
    }}
  />
);

export default ManagerRoute;
