import { useMutation } from "@apollo/react-hooks";

import {
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";

import { purple } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import gql from "graphql-tag";

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import logo from "../../assets/svgs/logo-omniq-01.svg";

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      currentUser {
        username
        roles
      }
    }
  }
`;

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#7cbb00",
    "&:hover": {
      backgroundColor: "#689f38",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },

  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  containerOne: {
    marginTop: 200,
  },
  row: {
    width: "50%",
    marginLeft: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "white",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#7cbb00",
  },
  margin: {
    margin: theme.spacing(1),
  },
  error: {
    color: "red",
  },
}));

const Login = ({ history, ...props }) => {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleUsernameInput = ({ target: { value } }) => {
    setErrorMessage("");
    setUsername(value);
  };

  const handlePasswordInput = ({ target: { value } }) => {
    setErrorMessage("");
    setPassword(value);
  };

  const [login] = useMutation(LOGIN, {
    onCompleted: ({ login }) => {
      console.log(login);
      if (login) {
        localStorage.setItem("qSession", JSON.stringify(login));
        history.replace("/tenants");
      } else {
        setErrorMessage("Username or password are wrong");
      }
    },
    onError: (error) => {
      console.error(error);
      setErrorMessage(error.message);
    },
  });

  const handleLoginButtonPressed = (e) => {
    e.preventDefault();

    if (username && password) {
      login({
        variables: {
          username,
          password,
        },
      });
    }

    localStorage.setItem("user", username);
  };

  return (
    <Container>
      <Grid container className={classes.containerOne}>
        <Grid container item xs={12}>
          <Grid item xs={5}>
            <img mb={50} src={logo} alt="Logo" />
          </Grid>
          <Grid item xs={1}>
            <div style={{ marginLeft: 50 }} className="vl"></div>
          </Grid>
          <Grid item xs={5}>
            <Container>
              <div className={classes.paper}>
                <form
                  style={{ background: "white" }}
                  className={classes.form}
                  noValidate
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoFocus
                    value={username}
                    onChange={handleUsernameInput}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    onChange={handlePasswordInput}
                    value={password}
                  />

                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        forgot username or password?
                      </Link>
                      <Typography className={classes.error}>
                        {errorMessage}
                      </Typography>
                    </Grid>
                  </Grid>
                  <ColorButton
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={!username || !password}
                    className={classes.submit}
                    onClick={handleLoginButtonPressed}
                  >
                    Sign In
                  </ColorButton>
                </form>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(Login);
