import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { createNetworkStatusNotifier } from "react-apollo-network-status";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { SessionHelper } from "../utils";

// You should use an absolute URL here
const options = {
  httpUri: process.env.REACT_APP_GRAPHQL_API || "http://localhost:4000/graphql",
  wsUri: process.env.REACT_APP_GRAPHQL_WS_API || "ws://localhost:4000/graphql",
};
console.log(options);

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();
const cache = new InMemoryCache();

let httpLink = new HttpLink({
  uri: options.httpUri,
});

// Create the subscription websocket link if available
if (options.wsUri) {
  const token = SessionHelper.token;
  const subscriptionClient = new SubscriptionClient(options.wsUri, {
    reconnect: true,
    connectionParams: () => ({
      authToken: token,
    }),
  });

  const wsLink = new WebSocketLink(subscriptionClient);

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  httpLink = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );
}

const authLink = setContext((_, { headers }) => {
  const token = SessionHelper.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

export { useApolloNetworkStatus, cache };

// Create the apollo client instance
export default new ApolloClient({
  link: link.concat(authLink.concat(httpLink)),
  cache,
  connectToDevTools: true,
});
