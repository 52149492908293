const SessionHelper = {
  get currentUser() {
    let session = localStorage.getItem("qSession");
    let currentUser = null;
    if (session) {
      session = JSON.parse(session);
      currentUser = session.currentUser;
    }
    return currentUser;
  },
  get token() {
    let session = localStorage.getItem("qSession");
    let token = "";
    if (session) {
      session = JSON.parse(session);
      token = session.token;
    }
    return token;
  },
};

export default SessionHelper;
