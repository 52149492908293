import { CssBaseline, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "./drawer";
import AppBar from "./toolbar";
;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "calc(100% - 60px)",
  },
}));

const ManagerLayout = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar />
      <Drawer />
      <main className={classes.content}>
        <Toolbar />
        {props.children}
      </main>
    </div>
  );
};

export default ManagerLayout;
