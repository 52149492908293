import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { SessionHelper } from '../src/utils/';
import logout from './components/logout';
import ManagerRoute from './components/Manager/ManagerRoute';
import error from './pages/404/index';
import Login from './pages/Auth/Login';
import SourceCreate from './pages/SourceList/Add';
import SourceEdit from './pages/SourceList/Edit';
import platforsource from './pages/SourceList/List';
import AddTenants from './pages/Tenant/Add';
import EditTenants from './pages/Tenant/Edit';
import TenantsList from './pages/Tenant/List';
import EditUser from './pages/User/Edit';
import RestrictedAccess from './pages/RestrictedAccess';
const Role = {
  manager: 'manager',
  agent: 'agent',
  supervisor: 'supervisor',
};

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => {
            const currentUser = SessionHelper.currentUser;

            return (
              <Redirect
                to={{ pathname: !!currentUser ? '/tenants' : '/login' }}
              />
            );
          }}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={logout} />
        <ManagerRoute
          exact
          path="/tenants"
          roles={[Role.manager]}
          component={TenantsList}
        />
        <ManagerRoute
          exact
          path="/edit-tenants/:id"
          roles={[Role.manager]}
          component={(props) => {
            const {
              match: { params },
            } = props;
            return <EditTenants id={params.id} />;
          }}
        />
        <ManagerRoute
          exact
          path="/add-tenants"
          roles={[Role.manager]}
          component={AddTenants}
        />
        <ManagerRoute
          exact
          path="/edit-user/:id"
          roles={[Role.manager]}
          component={(props) => {
            const {
              match: { params },
            } = props;
            return <EditUser id={params.id} />;
          }}
        />
        <ManagerRoute
          exact
          path="/managerSource"
          roles={[Role.supervisor, Role.manager]}
          component={platforsource}
        />
        <ManagerRoute
          exact
          path="/SourceEdit/:id"
          roles={[Role.supervisor, Role.manager]}
          component={(props) => {
            const {
              match: { params },
            } = props;
            return <SourceEdit id={params.id} />;
          }}
        />
        <ManagerRoute
          exact
          path="/SourceCreate"
          roles={[Role.supervisor, Role.manager]}
          component={SourceCreate}
        />

        <Route component={error} />
        <Route component={RestrictedAccess} />
      </Switch>
    </Router>
  );
}

export default App;
