const plaftormCase = [
  { value: "SMS_Twilio", label: "SMS Twilio" },
  { value: "WhatsApp_Twilio", label: "WhatsApp Twilio" },
  { value: "WhatsApp_ChatAPI", label: "WhatsApp ChatAPI" },
  { value: "WhatsApp_Botmaker", label: "WhatsApp Botmaker" },
  { value: "WhatsApp_Sunshine", label: "WhatsApp Sunshine" },
  { value: "WhatsApp_Sinch", label: "WhatsApp Sinch" },
  { value: "Instagram_Sunshine", label: "Instagram Sunshine" },
  { value: "Facebook_Messenger", label: "Facebook Messenger" },
  { value: "Instagram_Messenger", label: "Instagram Messenger" },
  { value: "Instagram_Feed", label: "Instagram Feed" },
  { value: "Facebook_Feed", label: "Facebook Feed" },
  { value: "web", label: "Web" },
  { value: "Twitter_Messenger", label: "Twitter_Messenger" },
  { value: "Twitter_Feed", label: "Twitter_Feed" },
  { value: "SMS_Tiaxa", label: "SMS Tiaxa" },
  { value: "LinkedIn_Feed", label: "LinkedIn_Feed" },
  { value: "WhatsApp_Dialog360", label: "WhatsApp_360" },
  { value: "WhatsApp_CloudAPI", label: "WhatsApp_CloudAPI" },
];

export default plaftormCase;
