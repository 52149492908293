import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { PowerSettingsNew } from "@material-ui/icons";
import gql from "graphql-tag";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: "#FFFFFF",
  },
}));

const READY_FOR = gql`
  mutation ReadyFor($readyFor: [String]!) {
    readyFor(readyFor: $readyFor)
  }
`;

function LogOutButton({ history, location }) {
  const classes = useStyles();
  const [readyFor] = useMutation(READY_FOR);
  const [open, setOpen] = useState(false);

  const handleLogoutPress = () => {
    setOpen(true);
  };

  const handleConfirmLogoutPress = () => {
    readyFor({
      variables: {
        readyFor: ["not available"],
      },
    })
      .then((r) => {
        history.replace("/logout");
        console.log(r);
      })
      .catch((e) => {
        console.error(e);
        history.replace("/logout");
      });

    setOpen(false);
  };

  const handleCancelLogoutPress = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        size="small"
        onClick={handleLogoutPress}
        className={classes.iconButton}
      >
        <PowerSettingsNew />
      </Button>
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to logout ?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelLogoutPress} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={handleConfirmLogoutPress} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withRouter(LogOutButton);
