import { Grid, Paper, TableContainer } from '@material-ui/core';
import React, { forwardRef, useState } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import AlertDialog from '../../components/utils/alertDialog';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import MaterialTable from 'material-table';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const NoChannel = (
  <div>
    <p> No Channel </p>
  </div>
);

const columns = [
  {
    id: 'Domain Name',
    title: 'Domain Name',
    label: 'Domain Name',
    minWidth: 120,
    field: 'domainName',
  },
  {
    id: 'Domain ID',
    title: 'Domain ID',
    label: 'Domain ID',
    minWidth: 120,
    field: 'domainId',
  },
  {
    id: 'channels',
    title: 'Channels',
    label: 'Channels',
    minWidth: 120,
    field: 'channels',
    render: ({ channels = NoChannel }) => (
      <div>
        {channels
          ? channels.map((channel) => (
              <p key={channel.value} label={channel.value}>
                {channel.value}
              </p>
            ))
          : NoChannel}
      </div>
    ),
  },
  {
    id: 'concurrent_users',
    title: 'Concurrent Users',
    label: 'Concurrent Users',
    minWidth: 120,
    field: 'concurrentUser',
  },
  {
    id: 'concurrent_sessions',
    title: 'Concurrent Sessions',
    label: 'Concurrent Sessions',
    minWidth: 120,
    field: 'concurrentSessions',
  },
  {
    id: 'onhold',
    title: 'Hold Sessions',
    label: 'Hold Sessions',
    minWidth: 120,
    field: 'onHoldUsers',
  },
  {
    id: 'active',
    title: 'Active',
    label: 'Active',
    minWidth: 120,
    field: 'isActive',
  },
  {
    id: 'adminNotify',
    title: 'Notifications',
    label: 'Notifications',
    minWidth: 120,
    field: 'adminNotification',
  },
];

function TenantsList(props) {
  const { data = {} } = useQuery(FETCH_TENANTS_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [alertOpen, setAlertOpen] = useState(false);

  const alertOption = (option) => {
    setAlertOpen(false);
  };

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  };

  const actions = [
    {
      icon: tableIcons.Edit,
      tooltip: 'Editar',
      onClick: (event, rowData) => {
        routeChange(`/edit-tenants/${rowData.id}`);
      },
    },

    {
      icon: tableIcons.Add,
      tooltip: 'Agregar',
      isFreeAction: true,
      onClick: (event, rowData) => routeChange(`/add-tenants`),
    },
  ];

  return (
    <Paper square>
      <Grid container>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper}>
            <MaterialTable
              columns={columns}
              data={data.tenants}
              icons={tableIcons}
              options={{
                search: true,
                sorting: true,
                actionsColumnIndex: -1,
              }}
              title="Tenants"
              actions={actions}
            />
          </TableContainer>
        </Grid>
        <AlertDialog open={alertOpen} alertOption={alertOption} />
      </Grid>
    </Paper>
  );
}

const FETCH_TENANTS_QUERY = gql`
  query {
    tenants {
      id
      domainName
      domainId
      channels
      concurrentUser
      concurrentSessions
      onHoldUsers
      isActive
      adminNotification
    }
  }
`;

export default TenantsList;
