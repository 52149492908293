import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, withRouter, Link } from 'react-router-dom';
import { useForm } from '../../components/utils/hooks';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Select from 'react-select';

const useStyles = makeStyles((theme) => ({
  wapperContainer: {
    marginTop: 50,
    marginBottom: 50,
  },
  formSelectControl: {
    padding: theme.spacing(1) + 4,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formContainerMarginTop: {
    marginTop: 30,
    paddingTop: theme.spacing(3),
  },
  centerLabel: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  row: {
    marginTop: theme.spacing(2),
  },
  textArea: {
    minHeight: 93,
  },
  tableMarginTop: {
    marginTop: theme.spacing(4),
  },
}));

const FETCH_TENANTS_QUERY = gql`
  query {
    tenants {
      id
      domainName
      domainId
      channels
      concurrentUser
      concurrentSessions
      onHoldUsers
      isActive
      adminNotification
    }
  }
`;

const UPDATE_TENANT = gql`
  mutation tenantUpdate($tenantInput: tenantInput) {
    tenantUpdate(tenantInput: $tenantInput)
  }
`;

const UPDATE_ROL_USER = gql`
  mutation tenantUserRolesUpdate($tenantInput: tenantInput) {
    tenantUserRolesUpdate(tenantInput: $tenantInput)
  }
`;

const Form = ({ data }) => {
  const snackbar = useSnackbar();
  const [channelsName, setchannelsName] = useState(
    data.tenant.channels || 'None'
  );

  const classes = useStyles();

  const [checkedNotify, setNotify] = useState(data.tenant.isActive);
  const [checked, setChecked] = useState(data.tenant.adminNotification);

  const [tenantUpdate] = useMutation(UPDATE_TENANT);
  const [tenantUserRolUpdate] = useMutation(UPDATE_ROL_USER);

  const history = useHistory();

  const routeChange = () => {
    let path = `/tenants`;
    history.push(path);
  };

  const handleNotify = (event) => {
    setNotify(!checkedNotify);
    console.log('SET NOTIFY >>>>> ', setNotify);
  };

  const handleChecked = (e) => {
    setChecked(!checked);
    console.log('SET CHECKED >>>> ', setChecked);
  };

  const roles = data.tenant.user ? data.tenant.user.roles : [];

  const { onChange, values } = useForm(UPDATE_TENANT, {
    id: data.tenant.id,
    domainName: data.tenant.domainName,
    domainId: data.tenant.domainId,
    concurrentUser: parseInt(data.tenant.concurrentUser),
    concurrentSessions: parseInt(data.tenant.concurrentSessions),
    onHoldUsers: parseInt(data.tenant.onHoldUsers),
    notificationText: data.tenant.notificationText,
    isActive: data.tenant.isActive,
    roles,
    adminNotification: data.tenant.adminNotification,
    adminRol:
      data.tenant.adminRol === null
        ? roles.includes('admin')
        : data.tenant.adminRol
        ? data.tenant.adminRol
        : roles.includes('admin'),
  });

  function handleAdminRol(e) {
    if (values.roles.includes('admin')) {
      if (values.adminRol) {
        var roles = values.roles;
        var index = roles.indexOf('admin');
        roles.splice(index, 1);
        var removeRolEvent = {
          target: { name: 'roles', value: roles },
        };
        var changeAdminRol = { target: { name: e.target.name, value: false } };
        onChange(removeRolEvent);
        onChange(changeAdminRol);
      }
    } else if (!values.roles.includes('admin')) {
      var oldRol = values.roles;
      oldRol.push('admin');
      var addRolEvent = {
        target: { name: 'roles', value: oldRol },
      };
      var changeAdminRolTrue = { target: { name: e.target.name, value: true } };
      onChange(addRolEvent);
      onChange(changeAdminRolTrue);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      tenantUpdate({
        variables: {
          tenantInput: {
            id: values.id,
            domainName: values.domainName,
            domainId: values.domainId,
            channels: channelsName,
            concurrentUser: parseInt(values.concurrentUser),
            concurrentSessions: parseInt(values.concurrentSessions),
            onHoldUsers: parseInt(values.onHoldUsers),
            adminNotification: checkedNotify,
            adminRol: values.adminRol,
            isActive: checked,
            notificationText: values.notificationText,
          },
        },
        refetchQueries: [{ query: FETCH_TENANTS_QUERY }],
        awaitRefetchQueries: true,
      })
        .then((r) => {
          console.log('response', r.data);
          tenantUserRolUpdate({
            variables: {
              tenantInput: {
                id: data.tenant.user.id,
                roles: values.roles,
              },
            },
          }).then((r) => {
            console.log('response', r.data);
            if (r.data.tenantUpdate !== null) {
              history.replace('/tenants');
              snackbar.showMessage('Tenant Edited');
            }
          });
        })
        .catch(console.error);
    } catch (e) {
      console.log(e);
    }

    e.target.reset();
  };

  const tenantData = data.tenant && data.tenant.user;

  return tenantData ? (
    <Container className={classes.wapperContainer}>
      <CssBaseline />
      <Grid item xs={12}>
        <Grid>
          <Typography variant="h5">{`Edit Tenant - ${values.domainName}`}</Typography>
        </Grid>
      </Grid>
      <Box>
        {'ID: '}
        {`${data.tenant.id}`}

        <Link to={`/edit-user/${data.tenant.user.id}`}>
          {'  '}
          {`${data.tenant.user.username}`}
        </Link>
      </Box>
      <Paper>
        <Container>
          <Grid item xs={12} className={classes.formContainerMarginTop}>
            <ValidatorForm onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="domainName" component="label">
                          Domain Name
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Box>
                        <TextField
                          fullWidth
                          id="name"
                          name="domainName"
                          value={values.domainName || ''}
                          required
                          placeholder="Domain Name"
                          onChange={onChange}
                          disabled
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  {/*  CUSTOMER  */}
                  <Grid container className={classes.row}>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="domainId" component="label">
                          Domain ID
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                      <Box>
                        <TextField
                          name="domainId"
                          id="domainId"
                          fullWidth
                          required
                          value={values.domainId || ''}
                          placeholder="Customer"
                          onChange={onChange}
                          disabled
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  {/*  CHANNELSS  */}
                  <Grid container className={classes.row}>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="channels" component="label">
                          Channels *
                        </FormLabel>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={8} lg={8}>
                      <Box>
                        <Select
                          isMulti
                          name="channels"
                          label="channels"
                          labelId="channels"
                          id="channels"
                          value={channelsName}
                          onChange={setchannelsName}
                          options={data.platforms}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isClearable
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.row}>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="concurrent_users" component="label">
                          Concurrent users
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                      <Box>
                        <TextField
                          name="concurrentUser"
                          id="concurrent_users"
                          type="number"
                          inputProps={{
                            min: '0',
                          }}
                          required
                          placeholder="25"
                          fullWidth
                          onChange={onChange}
                          value={values.concurrentUser || 25}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <Box className={classes.centerLabel}>
                        <FormLabel
                          htmlFor="concurrent_sessions"
                          component="label"
                        >
                          Concurrent sessions
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Box>
                        <TextField
                          name="concurrentSessions"
                          id="concurrent_sessions"
                          type="number"
                          inputProps={{
                            min: '0',
                          }}
                          required
                          placeholder="25"
                          onChange={onChange}
                          value={values.concurrentSessions || 25}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="on_hold_sessions" component="label">
                          On hold sessions
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Box>
                        <TextField
                          name="onHoldUsers"
                          id="on_hold_sessions"
                          type="number"
                          inputProps={{
                            min: '0',
                          }}
                          required
                          onChange={onChange}
                          placeholder="25"
                          value={values.onHoldUsers || 25}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid className={classes.row}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name="is Active"
                          onChange={handleChecked}
                          checked={checked}
                          value="active"
                        />
                      }
                      label="Is Active"
                    />
                  </Grid>
                  <Grid className={classes.row}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleNotify}
                            checked={checkedNotify}
                            name="adminNotification"
                            color="primary"
                            value="active"
                          />
                        }
                        label="Admin Notification"
                      />
                    </Box>
                  </Grid>
                  <Grid>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleAdminRol}
                            checked={values.adminRol}
                            name="adminRol"
                            color="primary"
                            value="active"
                            // disabled
                          />
                        }
                        label="Admin Rol"
                      />
                    </Box>
                  </Grid>
                  <Grid className={classes.row} item xs={12}>
                    <Box xs={12}>
                      <TextField
                        id="notificationText"
                        multiline
                        fullWidth
                        name="notificationText"
                        className={classes.textArea}
                        onChange={onChange}
                        value={values.notificationText || ''}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.row} item xs={12}>
                <Grid item xs={12} className={classes.gridButton}>
                  <Button color="primary" type="submit" onClick={routeChange}>
                    CANCEL
                  </Button>
                  <Button color="primary" type="submit">
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Container>
      </Paper>

      <Grid className={classes.tableMarginTop} item xs={12}></Grid>
    </Container>
  ) : (
    <div>
      <h3>Tenant Data is Broken</h3>
      <h3>Tenant ID: {data.tenant.id}</h3>
      <h3> Domain Name: {data.tenant.domainName}</h3>
    </div>
  );
};

const PLATFORM_TENANT_QUERY = gql`
  query Tenant($id: ID!) {
    tenant(id: $id) {
      id
      domainName
      domainId
      channels
      concurrentUser
      concurrentSessions
      onHoldUsers
      isActive
      adminNotification
      adminRol
      notificationText
      user {
        roles
        username
        id
      }
    }
    platforms {
      value
      label
    }
  }
`;

function EditTenants(props) {
  const { id } = props;

  const { data = {}, loading, error } = useQuery(PLATFORM_TENANT_QUERY, {
    variables: {
      id: id,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (!loading && data !== undefined) {
    return <Form data={data} />;
  }

  return error?.message || null;
}

export default withRouter(EditTenants, Form);
