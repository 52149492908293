import { ApolloConsumer } from "react-apollo";
import { Redirect } from "react-router-dom";
;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <ApolloConsumer>
    {(client) => {
      client.resetStore();
      localStorage.setItem("qSession", "");
      return <Redirect to="/Login" />;
    }}
  </ApolloConsumer>
);
