import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Container,
  FormLabel,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory, withRouter } from "react-router-dom";
import { useForm } from "../../components/utils/hooks";
;

const useStyles = makeStyles((theme) => ({
  wapperContainer: {
    marginTop: 50,
    marginBottom: 50,
  },
  formSelectControl: {
    padding: theme.spacing(1) + 4,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  gridButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formContainerMarginTop: {
    marginTop: 30,
    paddingTop: theme.spacing(3),
  },
  centerLabel: {
    alignItems: "center",
    display: "flex",
    height: "100%",
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  row: {
    marginTop: theme.spacing(2),
  },
  textArea: {
    minHeight: 93,
  },
  tableMarginTop: {
    marginTop: theme.spacing(4),
  },
}));

const FETCH_USERS_QUERY = gql`
  query {
    users {
      id
      username
      active
      lastLogin
      password
      status
      roles
      profile {
        id
      }
    }
  }
`;

const FETCH_USER = gql`
  query user($id: ID!) {
    user: tenantUser(id: $id) {
      id
      username
      active
      lastLogin
      password
      status
      roles
      profile {
        id
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation tenantUserUpdate($input: userInput) {
    tenantUserUpdate(userInput: $input)
  }
`;

const Form = ({ data, id }) => {
  const classes = useStyles();

  const [tenantUserUpdate] = useMutation(UPDATE_USER);

  const history = useHistory();

  const routeChange = () => {
    let path = `/tenants`;
    history.push(path);
  };

  const { onChange, values } = useForm(UPDATE_USER, {
    id: id,
    username: data.username,
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Password: ", values.password);
    try {
      tenantUserUpdate({
        variables: {
          input: {
            id: values.id,
            // username: values.username,
            password: values.password,
          },
        },
        refetchQueries: [{ query: FETCH_USERS_QUERY }],
        awaitRefetchQueries: true,
      })
        .then((r) => {
          console.log("response", r.data);
          if (r.data.userUpdate !== null) {
            history.replace("/tenants");
          }
        })
        .catch(console.error);
    } catch (e) {
      console.log(e);
    }

    e.target.reset();
  };

  return (
    <Container className={classes.wapperContainer}>
      <CssBaseline />

      {/* TITLE  */}
      <Grid item xs={12}>
        <Grid>
          <h2>{`Edit User - ${data.username}`}</h2>
        </Grid>
      </Grid>

      {/* TENANT ID  */}
      <div className="tenant_ID">
        <h3>
          <strong>ID: </strong> {values.id}
        </h3>
      </div>

      <Paper>
        <Container>
          <Grid item xs={12} className={classes.formContainerMarginTop}>
            <ValidatorForm onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {/*  TENANT NAME  */}
                  <Grid container>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="username" component="label">
                          Username *
                        </FormLabel>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={7}>
                      <Box>
                        <TextField
                          fullWidth
                          id="username"
                          name="username"
                          value={values.username || ""}
                          placeholder="Username"
                          onChange={onChange}
                          required
                          disabled
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="password" component="label">
                          Password *
                        </FormLabel>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={8} lg={8}>
                      <Box>
                        <TextField
                          name="password"
                          id="password"
                          type="password"
                          fullWidth
                          value={values.password}
                          placeholder="Password"
                          onChange={onChange}
                          required
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.row} item xs={12}>
                <Grid item xs={12} className={classes.gridButton}>
                  <Button color="primary" type="submit" onClick={routeChange}>
                    CANCEL
                  </Button>
                  <Button color="primary" type="submit">
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
};

function EditUser(props) {
  const { id } = props;

  const { data = {}, loading, error } = useQuery(FETCH_USER, {
    variables: {
      id: id,
    },
    fetchPolicy: "cache-and-network",
  });

  if (!loading && data !== undefined) {
    return <Form data={data.user} id={id} />;
  }

  return error?.message || null;
}

export default withRouter(EditUser, Form);
