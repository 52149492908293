import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
export default function AlertDialog({ open, alertOption, message }) {
  const handleClickOption = (option) => {
    alertOption(option);
  };

  return (
    <Grid>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-slide-title">
            {message
              ? message
              : "Are you sure you want to continue with this action ?"}
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClickOption(false)} color="primary">
            NO
          </Button>
          <Button
            onClick={() => handleClickOption(true)}
            color="secondary"
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
