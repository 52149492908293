import React from 'react'
import { Link } from 'react-router-dom'
import {
  Divider,
  Drawer,
  ListItemIcon,
  MenuList,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Dashboard,
  Book,
  Menu,
  ChevronLeft,
  ChevronRight,
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
const drawerWidth = 180
const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'white',
  },
  menucolor: {
    color: '#7CBB00',
  },
  icontransition: {
    color: '#7CBB00',
    margin: 4,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#777777',
    color: 'white',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    backgroundColor: '#777777',
    color: 'white',
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#777777',
    overflow: 'hidden',
    color: 'white',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#777777',
    color: 'white',
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

const MiniDrawer = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar} />
      <MenuList>
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, classes.menucolor, {
                [classes.hide]: open,
              })}
            >
              <Menu />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div>
          <IconButton
            onClick={handleDrawerClose}
            className={classes.icontransition}
          >
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button component={Link} to="/tenants">
            <ListItemIcon>
              <Dashboard className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Tenants" />
          </ListItem>
          {/* <ListItem button component={Link} to="#">
            <ListItemIcon>
              <Timeline className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItem> */}
          {/* awaiting its function */}
          <ListItem button component={Link} to="/managersource">
            <ListItemIcon>
              <Book className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="SourceList" />
          </ListItem>
        </List>
      </MenuList>
      <Divider />
    </Drawer>
  )
}

export default MiniDrawer
