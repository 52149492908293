import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Box,
  Button,
  Container,
  FormLabel,
  Grid,
  IconButton,
  FormControlLabel,
  Switch,
  Paper,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
} from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import {
  createTheme,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { Cancel, Save, Visibility, VisibilityOff } from '@material-ui/icons';
import gql from 'graphql-tag';
import { useSnackbar } from 'material-ui-snackbar-provider';
import React, { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import Title from '../../components/utils/title';
import sourceJson from './sourceJson';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7CBB01',
      contrastText: '#FFF',
    },
    secondary: {
      main: lightBlue[900],
      dark: '#146ca4',
    },
  },
  shape: {
    borderRadius: 20,
  },
});

const useStyle = makeStyles((theme) => ({
  wapperContainer: {
    marginTop: 50,
    marginBottom: 50,
  },
  formSelectControl: {
    padding: theme.spacing(1) + 4,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formContainerMarginTop: {
    marginTop: 30,
    paddingTop: theme.spacing(3),
  },
  centerLabel: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  row: {
    marginTop: theme.spacing(2),
  },
  textArea: {
    minHeight: 93,
  },
  tableMarginTop: {
    marginTop: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
    maxWidth: 700,
  },
  selectStyle: {
    minHeight: 275,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
  },
  containerCancel: {
    backgroundColor: '#c3292e',
    color: 'white',
    '&:hover': {
      backgroundColor: '#b21322',
    },
  },
  startAdornment: {
    '& .MuiButtonBase-root': {
      position: 'absolute',
      padding: 0,
      right: '5px',
      top: 'calc(50% - 12px)',
    },
  },
  tipsPreview: {
    color: '#ABABAB',
    fontSize: 15,
  },
}));

const GET_PLATFORMS = gql`
  query PlatformSource {
    platforms {
      id
      label
      value
      user
      status
      password
    }
  }
`;

const GET_PLATFORM = gql`
  query PlatformSource($id: ID!) {
    platform(id: $id) {
      id
      label
      value
      user
      status
      password
    }
  }
`;

const UPDATE_PLATFORM = gql`
  mutation platformsourceUpdate($platformsourceInput: platformsourceInput) {
    platformsourceUpdate(platformsourceInput: $platformsourceInput) {
      id
    }
  }
`;

const PlatformEdit = ({ id, data }) => {
  const classes = useStyle();
  const [userActive, setUserActive] = useState(data.status);
  const snackbar = useSnackbar();
  const [source] = useState(sourceJson);
  const [fields, setFields] = useState({
    id: id,
    value: data.value,
    label: data.label,
    status: data.status,
    user: data.user,
    password: data.password,
  });

  const [platformsourceUpdate] = useMutation(UPDATE_PLATFORM);
  const history = useHistory();

  const handleOnChangeActive = (e) => {
    setUserActive(!userActive);
  };

  const handleClickShowPassword = () => {
    setFields({ ...fields, showpassword: !fields.showpassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setFields({
      ...fields,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      platformsourceUpdate({
        variables: {
          platformsourceInput: {
            id: id,
            value: fields.value,
            label: fields.label,
            status: userActive,
            user: fields.user,
            password: fields.password,
          },
        },
        refetchQueries: [{ query: GET_PLATFORMS }],
        awaitRefetchQueries: true,
      })
        .then((r) => {
          if (r.data.platform !== null) {
            snackbar.showMessage('Saved');

            history.replace('/managerSource');
          }
        })
        .catch(console.error);
    } catch (e) {
      console.log(e);
    }

    e.target.reset();
  };

  return (
    <Container className={classes.wapperContainer}>
      <Grid item xs={12}>
        <Grid>
          <Title text="Source Edit" />
        </Grid>
      </Grid>
      <Paper>
        <Container className={classes.formContainerMarginTop}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <Box className={classes.centerLabel}>
                      <FormLabel htmlFor="value" component="label">
                        Source
                      </FormLabel>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Box>
                      <Select
                        fullWidth
                        required
                        name="value"
                        displayEmpty
                        value={fields.value}
                        onChange={handleOnChange}
                      >
                        <MenuItem value="" disabled>
                          Select a source
                        </MenuItem>
                        {source.map(({ value, label }, idx) => (
                          <MenuItem value={value} key={idx}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={classes.row} spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <Box className={classes.centerLabel}>
                      <FormLabel htmlFor="label" component="label">
                        Label
                      </FormLabel>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Box>
                      <TextField
                        className={classes.formSelectControl}
                        id="label"
                        name="label"
                        placeholder="Enter a Name"
                        onChange={handleOnChange}
                        fullWidth
                        value={fields.label || ''}
                      ></TextField>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container className={classes.row} spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <Box className={classes.centerLabel}>
                      <FormLabel htmlFor="user" component="label">
                        User
                      </FormLabel>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Box>
                      <TextField
                        className={classes.formSelectControl}
                        id="user"
                        name="user"
                        placeholder="Enter a User"
                        onChange={handleOnChange}
                        fullWidth
                        value={fields.user || ''}
                      ></TextField>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container className={classes.row} spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <Box className={classes.centerLabel}>
                      <FormLabel htmlFor="password" component="label">
                        Password
                      </FormLabel>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Box>
                      <TextField
                        className={classes.formSelectControl}
                        id="password"
                        name="password"
                        placeholder="Enter a Password"
                        type={fields.showpassword ? 'text' : 'password'}
                        value={fields.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {fields.showpassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleOnChange}
                        fullWidth
                      ></TextField>
                    </Box>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={userActive}
                          onChange={handleOnChangeActive}
                          value="active"
                        />
                      }
                      label="Active"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.row} spacing={2}>
                  <Grid item xs={12} md={4} lg={3}></Grid>
                  <Grid item xs={12} md={7}></Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.row} item xs={12}>
                <Grid item xs={12} className={classes.gridButton}>
                  <MuiThemeProvider theme={theme}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      style={{ margin: 3 }}
                      startIcon={<Save />}
                    >
                      Save
                    </Button>

                    <Button
                      startIcon={<Cancel />}
                      variant="contained"
                      type="button"
                      className={classes.containerCancel}
                      style={{ margin: 3 }}
                    >
                      <NavLink to="/managerSource" style={{ color: 'white' }}>
                        Cancel
                      </NavLink>
                    </Button>
                  </MuiThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Container>
      </Paper>
    </Container>
  );
};

const PlatformSourceEdit = (props) => {
  const { id } = props;

  const { data = {}, loading } = useQuery(GET_PLATFORM, {
    variables: {
      id: id,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (!loading && data !== undefined) {
    return <PlatformEdit data={data.platform} id={id} />;
  }

  return null;
};

export default withRouter(PlatformSourceEdit);
