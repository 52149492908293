// @flow

import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { ApolloProvider } from "react-apollo";
import App from "../App";
import client from "../config/apollo";
;

const SetupApp = (props) => {
  return (
    <ApolloProvider client={client}>
      <SnackbarProvider SnackbarProps={{ autoHideDuration: 2000 }}>
        <ConfirmProvider>
          <App />
        </ConfirmProvider>
      </SnackbarProvider>
    </ApolloProvider>
  );
};

export default SetupApp;
