import {
  AppBar,
  Avatar,
  Box,
  CircularProgress,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/svgs/q-logo.svg";
import { useApolloNetworkStatus } from "../../config/apollo";
import LogOutButton from "../logout/logoutbutton";
;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  select: {
    color: "white",
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  divider: {
    backgroundColor: "#fdd835",
    margin: "1%",
    useStyles: "none",
  },
  icons: {
    margin: 3,
    backgroundColor: "#777777",
  },
  appBar: {
    backgroundColor: "#777777",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  bigAvatar: {
    margin: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
  logoutButton: {
    marginLeft: "auto",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function AgentToolbar() {
  const classes = useStyles();
  const apolloStatus = useApolloNetworkStatus();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box>
          <Avatar src={logo} className={classes.bigAvatar} />
        </Box>
        <Box>
          {(apolloStatus.numPendingQueries > 0 ||
            apolloStatus.numPendingMutations > 0) && (
            <CircularProgress size={25} />
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          <h2>{localStorage.getItem("user")}</h2>
          <LogOutButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
